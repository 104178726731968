import featureFlags from '~/util/featureFlags';

class SecondaryNavMarketplaceData {
  constructor(userContext) {
    this.userContext = userContext;
  }

  getItems() {
    const isUserContextLoaded = this.userContext.is_guest !== undefined;
    const isLoggedIn = isUserContextLoaded && !this.userContext.is_guest;

    if (!isUserContextLoaded) return [];

    const items = [
      {
        identifier: 'marketplace-vendors',
        text: 'Explore Vendors',
        path: '/wedding-vendors', // needs to be a link for mobile
        dropdownItems: this.exploreVendorsDropdownItems(),
      },
    ];

    if (isLoggedIn) {
      items.push({
        identifier: 'marketplace-connections',
        text: 'Vendor Inquiries',
        path: '/wedding-vendors/inquiries',
      });
      items.push({
        identifier: 'marketplace-booked-vendors',
        text: 'Booked Vendors',
        path: '/wedding-vendors/inquiries/booked',
      });
    }

    if (!isLoggedIn) {
      items.push({
        identifier: 'marketplace-become-vendor',
        text: 'Become A Vendor',
        path: '/for-vendors',
      });
    }

    return items;
  }

  exploreVendorsDropdownItems() {
    const items = [
      { text: 'Wedding Venues', path: '/wedding-vendors/wedding-venues' },
      { text: 'Photographers', path: '/wedding-vendors/wedding-photographers' },
      { text: 'Videographers', path: '/wedding-vendors/wedding-videographers' },
      { text: 'Caterers', path: '/wedding-vendors/wedding-catering' },
      { text: 'Florists', path: '/wedding-vendors/wedding-florists' },
      { text: 'Beauty Professionals', path: '/wedding-vendors/wedding-hair-makeup' },
      { text: 'Wedding Planners', path: '/wedding-vendors/wedding-planners' },
      { text: 'Bands & DJs', path: '/wedding-vendors/wedding-bands-djs' },
      { text: 'Cakes & Desserts', path: '/wedding-vendors/wedding-cakes-desserts' },
      { text: 'Bar Services & Beverages', path: '/wedding-vendors/wedding-bar-services' },
    ];
    if (featureFlags.get('enableOfficiantsAndExtras')) {
      items.push(
        { text: 'Officiants', path: '/wedding-vendors/wedding-officiants' },
        { text: 'Event Extras', path: '/wedding-vendors/wedding-extras' }
      );
    }
    return items;
  }
}

export default SecondaryNavMarketplaceData;
