import featureFlags from '~/util/featureFlags';

class SecondaryNavPlanningData {
  constructor(userContext) {
    this.userContext = userContext;
  }

  getItems() {
    const currentPath = window.location.pathname;
    const items = this.userContext.has_wedding_account
      ? this.weddingAccountItems()
      : this.nonWeddingAccountItems();
    return this.setActiveItem(items, currentPath);
  }

  setActiveItem(items, currentPath) {
    return items.map((item) => {
      const currentItem = item;

      if (currentItem.text === 'Wedding Vendors') {
        // Make this item active for any vendor- or venue-related path, including wedding-venues,
        // wedding-photographers, wedding-florists, etc.
        currentItem.isActive =
          currentPath.indexOf('/inspire') > -1 || currentPath.indexOf('/wedding-vendors') === 0;
      } else {
        currentItem.isActive = currentPath.indexOf(currentItem.path) > -1;
      }

      return currentItem;
    });
  }

  weddingAccountItems() {
    const items = [
      {
        identifier: 'planning-dashboard',
        text: 'Planning Dashboard',
        path: '/wedding/manage',
      },
      {
        identifier: 'guest-list',
        text: 'Guest List',
        path: '/wedding/manage/guests',
        dropdownItems: this.guestListDropdownItems(),
        className: 'hidden-sm hidden-xs',
      },
      {
        identifier: 'guest-list',
        text: 'Events',
        path: '/wedding/manage/events',
        className: 'hidden-sm hidden-xs',
      },
      {
        identifier: 'wedding-checklist',
        text: 'Wedding Checklist',
        path: '/wedding/manage/wedding-checklist',
        className: 'hidden-sm hidden-xs',
      },
      {
        identifier: 'real-weddings',
        text: 'Real Weddings',
        path: '/inspiration',
        dropdownItems: this.realWeddingsDropdownItems(),
      },
      {
        identifier: 'explore-vendors',
        text: 'Explore Vendors',
        path: '/wedding-vendors',
        dropdownItems: this.exploreVendorsDropdownItems(),
      },
      {
        identifier: 'expert-advice',
        text: 'Expert Advice',
        path: '/expert-advice',
      },
    ];

    return items;
  }

  nonWeddingAccountItems() {
    const { userContext } = this;
    const createWeddingAccount =
      !userContext.has_wedding_account && userContext.has_completed_onboarding;
    const items = [
      {
        identifier: 'wedding-checklist',
        text: 'Wedding Checklist',
        path: createWeddingAccount
          ? `/wedding/manage/wedding-checklist`
          : '/wedding-planning/checklist',
      },
      {
        identifier: 'guest-list',
        text: 'Guest List',
        path: createWeddingAccount ? `/wedding/manage/guests/all` : '/wedding-planning/guests',
      },
      {
        identifier: 'real-weddings',
        text: 'Real Weddings',
        path: '/inspiration',
        dropdownItems: this.realWeddingsDropdownItems(),
      },
      {
        identifier: 'explore-vendors',
        text: 'Explore Vendors',
        path: '/wedding-vendors',
        dropdownItems: this.exploreVendorsDropdownItems(),
      },
      {
        identifier: 'expert-advice',
        text: 'Expert Advice',
        path: '/expert-advice',
      },
    ];

    return items;
  }

  guestListDropdownItems() {
    const items = [
      { text: 'All Guests', path: '/wedding/manage/guests/all' },
      { text: 'Build Guest List', path: '/wedding/manage/guests/build' },
      { text: 'Address Envelopes', path: '/wedding/manage/guests/envelopes' },
      { text: 'Track RSVPs', path: '/wedding/manage/guests/rsvps/overview' },
    ];

    // TODO: Add 'has_guest_requests' to the user context
    if (this.userContext.has_guest_requests) {
      items.push({ text: 'View RSVP Requests', path: '/wedding/manage/guests/rsvp-notifications' });
    }

    return items;
  }

  realWeddingsDropdownItems() {
    return [
      {
        text: 'Austin, TX',
        path: '/inspiration/real-weddings/austin-tx',
      },
      {
        text: 'Charlottesville, VA',
        path: '/inspiration/real-weddings/charlottesville-va',
      },
      {
        text: 'Charleston, SC',
        path: '/inspiration/real-weddings/charleston-sc',
      },
      {
        text: 'Chicago, IL',
        path: '/inspiration/real-weddings/chicago-il',
      },
      {
        text: 'Denver, CO',
        path: '/inspiration/real-weddings/denver-co',
      },
      {
        text: 'Miami, FL',
        path: '/inspiration/real-weddings/miami-fl',
      },
      {
        text: 'Napa, CA',
        path: '/inspiration/real-weddings/napa-ca',
      },
      {
        text: 'Nashville, TN',
        path: '/inspiration/real-weddings/nashville-tn',
      },
      {
        text: 'New Orleans, LA',
        path: '/inspiration/real-weddings/new-orleans-la',
      },
      {
        text: 'New York, NY',
        path: '/inspiration/real-weddings/new-york-ny',
      },
      {
        text: 'Richmond, VA',
        path: '/inspiration/real-weddings/richmond-va',
      },
      {
        text: 'San Francisco, CA',
        path: '/inspiration/real-weddings/san-francisco-ca',
      },
      {
        text: 'Seattle, WA',
        path: '/inspiration/real-weddings/seattle-wa',
      },
      {
        text: 'Tampa, FL',
        path: '/inspiration/real-weddings/tampa-fl',
      },
      {
        text: 'View All Weddings',
        path: '/inspiration/real-weddings',
      },
    ];
  }

  exploreVendorsDropdownItems() {
    const items = [
      { text: 'Wedding Venues', path: '/wedding-vendors/wedding-venues' },
      { text: 'Photographers', path: '/wedding-vendors/wedding-photographers' },
      { text: 'Videographers', path: '/wedding-vendors/wedding-videographers' },
      { text: 'Caterers', path: '/wedding-vendors/wedding-catering' },
      { text: 'Florists', path: '/wedding-vendors/wedding-florists' },
      { text: 'Beauty Professionals', path: '/wedding-vendors/wedding-hair-makeup' },
      { text: 'Wedding Planners', path: '/wedding-vendors/wedding-planners' },
      { text: 'Bands & DJs', path: '/wedding-vendors/wedding-bands-djs' },
      { text: 'Cakes & Desserts', path: '/wedding-vendors/wedding-cakes-desserts' },
      { text: 'Bar Services & Beverages', path: '/wedding-vendors/wedding-bar-services' },
    ];
    if (featureFlags.get('enableOfficiantsAndExtras')) {
      items.push(
        { text: 'Officiants', path: '/wedding-vendors/wedding-officiants' },
        { text: 'Event Extras', path: '/wedding-vendors/wedding-extras' }
      );
    }
    return items;
  }
}

export default SecondaryNavPlanningData;
